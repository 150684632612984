<template>
  <HModal
    ref="modal"
    :auto-opening="autoOpening"
    :always-opening="alwaysOpening"
  >
    <VCard
      color="white"
      no-padding
    >
      <div class="row">
        <div class="col-12 col-md-6 p-0">
          <NuxtImg
            format="webp"
            loading="lazy"
            quality="80"
            fit="outside"
            src="/img/livre-blanc-mandataire.jpg"
            class="whitebook__img"
          />
        </div>
        <div class="col-12 col-md-6 p-5">
          <HH3
            class="mb-4"
          >
            homeloop recrute ses futurs mandataires immobiliers !
          </HH3>
          <p class="mb-4">
            Rejoins dès aujourd'hui, l'immobilier demain. Propose (enfin) un service vraiment différenciant à tes clients : <b>l'achat instantané.</b>
          </p>
          <LazyHContactForm
            :use="contactFormOptions"
            acquisition-source="livre_blanc_recrutement"
            @submit:end="download"
          >
            <template #submitButtonLabel>
              Télécharge notre plaquette de recrutement.
            </template>
          </LazyHContactForm>
        </div>
      </div>
    </VCard>
  </HModal>
</template>

<script lang="ts" setup>
withDefaults(defineProps<{
  autoOpening?: boolean
  alwaysOpening?: boolean
}>(), {
  autoOpening: true
})

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const viewport = useViewport()

const modal = ref(true)

const contactFormOptions = {
  email: {
    activated: true,
    verification: false
  },
  phone: {
    activated: false,
    verification: false
  },
  names: {
    activated: false
  },
  cgu: {
    activated: false
  },
  optin: {
    activated: true,
    required: true
  },
  is_selling: {
    activated: false
  },
  company: {
    activated: false,
    toggle: false
  },
  second_contact: {
    activated: false,
    toggle: false,
    label: 'annonces.secondBuyer'
  },
  partner_optin: {
    activated: false,
    label: 'annonces.partner_optin'
  },
  agent_profession: {
    activated: false
  },
  disabledField: true
}

function download () {
  const link = document.createElement('a')
  link.download = 'livre_blanc_homeloop'
  link.href = '/pdf/livre_blanc_mandataire_homeloop.pdf'
  link.dispatchEvent(new MouseEvent('click'))

  modal.value.close()
}
</script>

<style lang="scss" scoped>
.whitebook__img {
  aspect-ratio: 4 / 3;
  object-fit: cover;
  height: 100%;
  width: 100%;

  @include media-breakpoint-down(md) {
    height: auto;
  }
}
</style>
