<template>
  <VDialog
    v-model="popup"
    max-width="1200"
    @click:outside="close"
  >
    <slot />
    <VBtn
      color="primary"
      class="mt-3"
      @click="close"
    >
      Fermer
    </VBtn>
  </VDialog>
</template>

<script setup lang="ts">
const props = defineProps<{
  autoOpening?: boolean
  alwaysOpening?: boolean
}>()

// eslint-disable-next-line vue/require-prop-types
const popup = defineModel({ default: false })

defineExpose({
  close
})

onMounted(() => {
  if (props.autoOpening) {
    if (sessionStorage.getItem('popup') === null) {
      sessionStorage.setItem('popup', 'true')
    }

    setTimeout(() => {
      popup.value = JSON.parse(sessionStorage.getItem('popup') || 'true')
    }, 15000)
  }
})

watch(() => props.alwaysOpening, (value: boolean) => {
  if (value) {
    popup.value = value
  }
})

function close () {
  popup.value = false
  if (props.autoOpening) {
    sessionStorage.setItem('popup', 'false')
  }
}
</script>

<style scoped>

</style>
